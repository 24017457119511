import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Set up a ProFTP Server for your WQHD Camera",
  "path": "/Frequently_Asked_Question/ProFTP_Server_for_your_WQHD_Camera/",
  "dateChanged": "2022-05-26",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Set up a ProFTP Server for your WQHD Camera' dateChanged='2022-05-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ProFTP_Server_for_your_WQHD_Camera/' locationFR='/fr/Frequently_Asked_Question/ProFTP_Server_for_your_WQHD_Camera/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "set-up-a-proftp-server-for-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#set-up-a-proftp-server-for-your-wqhd-camera",
        "aria-label": "set up a proftp server for your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a ProFTP Server for your WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The simplest way would be to use your `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`Internet Router as FTP Server`}</a>{`. If you want to use a Linux Server like an Raspberry Pi we recommend using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/"
      }}>{`sFTP Service`}</a>{` instead of FTP or FTPS. But of course you can also set up your own FTP server instead. I would recommend `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`building an vsftpd Docker image`}</a>{` for this task.`}</p>
    <p>{`Or you can install the server software directly from your Linux Console. In the following tutorial I want to go throught the steps of setting up ProFTPD with a self-signed TLS ertificate and connecting with an WQHD and Full HD camera.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-proftp-on-debian-linux"
        }}>{`Install ProFTP on Debian Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-the-ftp-user"
        }}>{`Add the FTP User`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testing-the-login-with-filezilla-optional"
            }}>{`Testing the Login with Filezilla optional`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#set-up-tls-encryption"
        }}>{`Set up TLS Encryption`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#generate-certificate-and-key"
            }}>{`Generate Certificate and Key`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configure-proftpd"
            }}>{`Configure ProFTPD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testing-the-login-with-filezilla-optional"
            }}>{`Testing the Login with Filezilla optional`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-an-instar-ip-camera"
        }}>{`Connecting an INSTAR IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wqhd-cameras"
            }}>{`WQHD Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#full-hd-cameras"
            }}>{`Full HD Cameras`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "install-proftp-on-debian-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install-proftp-on-debian-linux",
        "aria-label": "install proftp on debian linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install ProFTP on Debian Linux`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` upgrade -y
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` proftpd-basic -y`}</code></pre></div>
    <p>{`We can now edit the `}<a parentName="p" {...{
        "href": "https://gist.github.com/mpolinowski/2be6aebe79c4439a72b41aea455fceba"
      }}>{`default ProFTP configuration file`}</a>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/proftpd/proftpd.conf`}</code>{`. Or alternativly, create a custom configuration file in the directory `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/proftpd/conf.d/`}</code>{`. This will override rules from the default configuration file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/proftpd/conf.d/custom.conf`}</code></pre></div>
    <p>{`Add the following content to this configuration file and adjust it if necessary:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# FTP users don't need a valid shell`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Global`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    RequireValidShell off
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`/Global`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Disable IPv6 (if wanted)`}</span>{`
UseIPv6 off

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Set home directory as root directory for FTP users`}</span>{`
DefaultRoot ~

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Allow login only for users of the group "ftpuser"`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Limit LOGIN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    DenyGroup `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ftpuser
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`/Limit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# In some cases you have to specify passive ports range to by-pass`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# firewall limitations. Ephemeral ports can be used for that, but`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# feel free to use a more narrow range.`}</span>{`
PassivePorts `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4242`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4243`}</span></code></pre></div>
    <p>{`Restart the ProFTPD Server and check if the service is satisfied with your configuration:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl restart proftpd`}</code></pre></div>
    <p>{`And we can also make sure that our firewall - for example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ufw`}</code>{` - will allow clients to connect:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ufw allow `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:21/tcp
ufw allow `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4242`}</span>{`:4243/tcp
ufw reload`}</code></pre></div>
    <h2 {...{
      "id": "add-the-ftp-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-the-ftp-user",
        "aria-label": "add the ftp user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add the FTP User`}</h2>
    <p>{`Now you have to create the group `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ftpuser`}</code>{`. Use the following command to create the group:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`addgroup ftpuser`}</code></pre></div>
    <p>{`Next, we have to create the user that is allowed to log in to the FTP server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`adduser ipcamera --shell /bin/false --home /home/ipcamera
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`passwd`}</span>{` ipcamera`}</code></pre></div>
    <p>{`Add the FTP user you just created to the group `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ipcamera`}</code>{` so that this user can log in to the FTP server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`adduser ipcamera ftpuser`}</code></pre></div>
    <h3 {...{
      "id": "testing-the-login-with-filezilla-optional",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing-the-login-with-filezilla-optional",
        "aria-label": "testing the login with filezilla optional permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the Login with Filezilla (optional)`}</h3>
    <p>{`If you have the Filezilla client at hand you can now use it to connect with your server using the new `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ftpuser`}</code>{` and the local IP of your server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/393aa/ProFTP_Server_for_your_WQHD_Camera_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACCUlEQVQoz1XQaW/UMBAG4HwGekHWOXzFjnM4thPHuTbNbpYt0EIr0f//b1C2UAnp0auZDzPSjEcZZzzFhCJMaJLQhGFCMCExRBgTdBFFcRjF0cVWxBAiDBH2tO1cNxa6zqVSxurGVaYplSmkZiJP0pylGRM5zwomNiKXCRcQYYSJJ/uRmTarmqxqhKzfFMr287qcH78///7x8vr48vr18dlOi+lnN688l7e3d74PvPThFK5HEMIguAg3/i4qtNVuarp946bKuFLZXNamHXU7Iso+fvxwfX3jlaVmiQhjEkb4XRBAaZwdl/7+NB3Ow/1pPJzb8eCmY3d/ykoDA0RI4rE0R4RH/w+DAFZ1106HbjrUbtJ20HZQdlB1b9yepyW4CWIfeUGIwhCFEdn8WwECKGtn3CQKRVmGCMc03RBOWIYQ312D4C72LkciEMQggABsGYQQgKjUthmXup+7edVuUu24sYPp54QVXz75wW3kIZriRFCeU5YnvNgW0xRipu3Qz0dpXFV3WVULad5kqmGiJChJSOoVxuluv377NR0fjg8/u3ktjJPNwLMqDBEAse+Hu130DoAYIQYBpjHzZDvW/bycn8blvJyfunmVdlBuyrWlPE/SkokNzyQXkokyLRSl2e4KRJ+hx3O1yapLqr9trtJCZ7IWpUkLXSjbdPvLz0c3Hjgvd1fbw/4Aww1q6bwag14AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/e4706/ProFTP_Server_for_your_WQHD_Camera_01.avif 230w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/d1af7/ProFTP_Server_for_your_WQHD_Camera_01.avif 460w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/7f308/ProFTP_Server_for_your_WQHD_Camera_01.avif 920w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/17ac9/ProFTP_Server_for_your_WQHD_Camera_01.avif 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/a0b58/ProFTP_Server_for_your_WQHD_Camera_01.webp 230w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/bc10c/ProFTP_Server_for_your_WQHD_Camera_01.webp 460w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/966d8/ProFTP_Server_for_your_WQHD_Camera_01.webp 920w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/b6585/ProFTP_Server_for_your_WQHD_Camera_01.webp 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/81c8e/ProFTP_Server_for_your_WQHD_Camera_01.png 230w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/08a84/ProFTP_Server_for_your_WQHD_Camera_01.png 460w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/c0255/ProFTP_Server_for_your_WQHD_Camera_01.png 920w", "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/393aa/ProFTP_Server_for_your_WQHD_Camera_01.png 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efb4d1dbb8e3ed2479587606ecfd7bb1/c0255/ProFTP_Server_for_your_WQHD_Camera_01.png",
              "alt": "Set ProFTP Server for your WQHD Camera",
              "title": "Set ProFTP Server for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since we limited to PASV port range to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4242`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4243`}</code>{` and made sure that our firewall gives us access to those ports we can now use either the Active (`}<strong parentName="p">{`PORT`}</strong>{`) or Passive (`}<strong parentName="p">{`PASV`}</strong>{`) mode to connect:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eea212d164764b19cba62db9d1155011/c1b63/ProFTP_Server_for_your_WQHD_Camera_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB1UlEQVQoz22Q2W7bMBBF9ZymQJ3Y2kiKiyiSIkVRK6V4Se1sLfrQ//+cQrbTBm2Bg8Hw4czMZZARQvMcYQwgwoRkmKAsyzCGCC39+ZECmKTgSpImKQAQAYgCbdthnJStuSyNdcY12rrSVEIZkheEC8wF5ZJxSc/kQhHGAUQQZUE1TqxqhWmEaQrtLgjT7I4vLz9+fj29juPOz4f905vtH3Tj3bglXK1Wd1EUB8VhGz+dQoCjCEQxXEjQZpNw7Xb747Gb9sP02Hrdemk7UbVlMwLMbj7drFZ3gZAmz0WSZnGCfhNFgJe28btxmCe/7TpflLWqe1X3ZTNSrkAMMWEBpUWWsb/lGOayqrpJn3cq1xfaLbLtdDMSKqMvCYpwcDk1XuQsfh9xkZUblO0W6r50w1VuR0zE+nOYrmEQxUvUMAILYRouyUEYJbk0V+Ej7/L97Sa5BwHCHBFOmCRMUq4IEwhzmOVFWf9HPmdmhc4gyWkRKDeY1h+ev8+PL4fnb35/knW/HOwGabt/UW6guUrXgAAWmNZX3bw9vvr90/b4Nm6PuhlN6003fcQOD7afq35uph3Ly/VtCDYoYEKzQlN+rsVS/yAuTcllZRuvbadt5/qZUnn5sF9qQm3bpO+6zQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eea212d164764b19cba62db9d1155011/e4706/ProFTP_Server_for_your_WQHD_Camera_02.avif 230w", "/en/static/eea212d164764b19cba62db9d1155011/d1af7/ProFTP_Server_for_your_WQHD_Camera_02.avif 460w", "/en/static/eea212d164764b19cba62db9d1155011/7f308/ProFTP_Server_for_your_WQHD_Camera_02.avif 920w", "/en/static/eea212d164764b19cba62db9d1155011/07e28/ProFTP_Server_for_your_WQHD_Camera_02.avif 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eea212d164764b19cba62db9d1155011/a0b58/ProFTP_Server_for_your_WQHD_Camera_02.webp 230w", "/en/static/eea212d164764b19cba62db9d1155011/bc10c/ProFTP_Server_for_your_WQHD_Camera_02.webp 460w", "/en/static/eea212d164764b19cba62db9d1155011/966d8/ProFTP_Server_for_your_WQHD_Camera_02.webp 920w", "/en/static/eea212d164764b19cba62db9d1155011/92f8c/ProFTP_Server_for_your_WQHD_Camera_02.webp 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eea212d164764b19cba62db9d1155011/81c8e/ProFTP_Server_for_your_WQHD_Camera_02.png 230w", "/en/static/eea212d164764b19cba62db9d1155011/08a84/ProFTP_Server_for_your_WQHD_Camera_02.png 460w", "/en/static/eea212d164764b19cba62db9d1155011/c0255/ProFTP_Server_for_your_WQHD_Camera_02.png 920w", "/en/static/eea212d164764b19cba62db9d1155011/c1b63/ProFTP_Server_for_your_WQHD_Camera_02.png 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eea212d164764b19cba62db9d1155011/c0255/ProFTP_Server_for_your_WQHD_Camera_02.png",
              "alt": "Set ProFTP Server for your WQHD Camera",
              "title": "Set ProFTP Server for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should be able to connect and uploade / create items.`}</p>
    <h2 {...{
      "id": "set-up-tls-encryption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-up-tls-encryption",
        "aria-label": "set up tls encryption permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up TLS Encryption`}</h2>
    <p>{`The FTP protocol transfers login information as well as sent data in cleartext. The most common solution is encryption via TLS, which can be set up with minimal effort using the free OpenSSL software.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` openssl`}</code></pre></div>
    <p>{`Starting by adding to our custom configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`custom.conf`}</code>{` from the previous step we now need to add a TLS certificate to our server. If your server runs on a public server you can use a service like Let's Encrypt / Certbot to generated a valid certificate for the domain name of your server. You can use this certificate for your Web as well as your FTP server. Otherwise you can follow our tutorial on how to `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/CA_Certificate/#take-matters-into-your-own-hands"
      }}>{`generate your own CA certificate`}</a>{`. This tutorial goes through the steps of generating a valid CA cert for a local domain (provided by an AVM Fritzbox Home Internet Router) and put it to use with an `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`external MQTT Broker`}</a>{`. The same certificate can be used here as well.`}</p>
    <p>{`But in the following steps we will simply generate a self-signed certificate that can be used with an local IP address (instead of a domain name). Of course, this means that we will have to activate the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`insecure`}</code>{` mode in our cameras FTP client. This does not mean that the encryption will be weaker, but the camera will not try to verify if your personal FTP server really is the server the TLS certificate was issued to.`}</p>
    <h3 {...{
      "id": "generate-certificate-and-key",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#generate-certificate-and-key",
        "aria-label": "generate certificate and key permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generate Certificate and Key`}</h3>
    <p>{`Start by creating a directory for your certificate `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mkdir /etc/proftpd/ssl`}</code>{` then run the following command to generate the key and certificate with a lifetime of 1 year:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -new -x509 -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`365`}</span>{` -nodes -out /etc/proftpd/ssl/proftpd.cert.pem -keyout /etc/proftpd/ssl/proftpd.key.pem`}</code></pre></div>
    <p>{`Since we are not planning to verify this certificate you can leave all the answers blank except the FQDN - here you can add the local IP of your FTP server, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Generating a RSA private key
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`+++++
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.+++++
writing new private key to `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/etc/proftpd/ssl/proftpd.key.pem'`}</span>{`
-----
You are about to be asked to enter information that will be incorporated
into your certificate request.
What you are about to enter is what is called a Distinguished Name or a DN.
There are quite a few fields but you can leave some blank
For some fields there will be a default value,
If you enter `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.'`}</span>{`, the field will be left blank.
-----
Country Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` letter code`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`AU`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
State or Province Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Some-State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Locality Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, city`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Organization Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, company`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Internet Widgits Pty Ltd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Organizational Unit Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Common Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e.g. server FQDN or YOUR name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:192.168.2.111
Email Address `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.`}</code></pre></div>
    <h3 {...{
      "id": "configure-proftpd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-proftpd",
        "aria-label": "configure proftpd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure ProFTPD`}</h3>
    <p>{`Now create a configuration file to activate the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mod_tls`}</code>{` module in proFTPD `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/proftpd/conf.d/tls.conf`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`IfModule`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`mod_tls.c`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    TLSEngine on
    TLSLog /var/log/proftpd/tls.log
    TLSProtocol TLSv1.2 TLSv1.3
    TLSRSACertificateFile /etc/proftpd/ssl/proftpd.cert.pem
    TLSRSACertificateKeyFile /etc/proftpd/ssl/proftpd.key.pem
    TLSVerifyClient off
    TLSOptions NoSessionReuseRequired
    TLSRequired on
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`IfModule`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: INSTAR Full HD Camera require `}<strong parentName="p">{`TLSv1.2`}</strong>{` while INSTAR WQHD Cameras use the newer `}<strong parentName="p">{`TLSv1.3`}</strong>{`. You can remove the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`TLSv1.2`}</code>{` configuration if you only work with WQHD Cameras.`}</p>
    </blockquote>
    <p>{`Now make sure that the TLS module is actually active:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/proftpd/modules.conf`}</code></pre></div>
    <p>{`The un-comment the following lines - if necessary:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Install proftpd-mod-crypto to use this module for TLS/SSL support.`}</span>{`
LoadModule mod_tls.c
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Even these modules depend on the previous one`}</span>{`
LoadModule mod_tls_fscache.c
LoadModule mod_tls_shmcache.c`}</code></pre></div>
    <p>{`And restart the proFTPD service:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` proftpd restart
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` proftpd status`}</code></pre></div>
    <h3 {...{
      "id": "testing-the-login-with-filezilla-optional-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing-the-login-with-filezilla-optional-1",
        "aria-label": "testing the login with filezilla optional 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the Login with Filezilla (optional)`}</h3>
    <p>{`Before we used Filezilla Client to access our FTP server and the Fillezilla log will have displayed that no encryption was being used connecting you your server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Status:	Connecting to `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111:21`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Connection established, waiting `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` welcome message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Insecure server, it does not support FTP over TLS.
Status:	Logged `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{`
Status:	Retrieving directory listing of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Directory listing of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span>{` successful`}</code></pre></div>
    <p>{`This time you will be greeted by a pop-up screen asking you to verify your own certificate - you can check the option to always trust this certificate in the future:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/1790f/ProFTP_Server_for_your_WQHD_Camera_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.39130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACH0lEQVQoz3XS2Y6bMBQGYO4nCynEGLDNFsJuFm+AszCdSL3s+79NxXQqtZOp9Ml3v85/jmyc0nNDaV6WJI6jMEpO6TnLT2mKMPYR9nyMSYBIgDD5xEfYsI7ANE0IIcbYsu3D4ZsL3YN5MM2DZdmWZWMSeJ4PgPPMoEmKoyRJsyBKklN2zoowThCJEA5JGLuev9lstrvdl4yfP0auX8fbdz7fhV7G2xuf78N4ZeNV6CWrqOO4yAt8F3/mYYNypZeHmG+9uvRKt3yqernqRMOn5FwBE3o2di30zChaIfSd8qlhquVTJ+ZO6l7qTszVIONTedwCcHCBCYEJHdP9m5FTxqarmNf8fH/M98d0e9PLQ11eW6mTtDpuATx4n2If4aqXYr7Xg6p6WXYia4aMspyyrBlyypKsQh4hKCQoxH4YkjgiyfoGSUSSNczGK2VjwyfKp7qXRcsLuip7GaclMKFr+Z6NECDYCfwjdi3/fWffKDohL0uv9KAu6rIMSjdMNWxs2EjFtO68AY651oZ/2oI9BHvo7KFRtLyXehivndCtmMuW5++1c8rWyafyuAPO/3Zu2MjnWyumNdmJshP1oDq53rxmY3Qq7M0R7F2wg8+MqpNSL1Iv6voqL4u8LK2Yfh9vPVheBzgiJCL4C0bZiZavYz/wqWh51at6UJRPcVZZlg0ceATOM4MkZxz/g8TntGjyusvrDgXxy8vLbrv/8nP/AnIZfPc4PAHxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/e4706/ProFTP_Server_for_your_WQHD_Camera_03.avif 230w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/d1af7/ProFTP_Server_for_your_WQHD_Camera_03.avif 460w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/7f308/ProFTP_Server_for_your_WQHD_Camera_03.avif 920w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/c93d7/ProFTP_Server_for_your_WQHD_Camera_03.avif 1189w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/a0b58/ProFTP_Server_for_your_WQHD_Camera_03.webp 230w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/bc10c/ProFTP_Server_for_your_WQHD_Camera_03.webp 460w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/966d8/ProFTP_Server_for_your_WQHD_Camera_03.webp 920w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/1671b/ProFTP_Server_for_your_WQHD_Camera_03.webp 1189w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/81c8e/ProFTP_Server_for_your_WQHD_Camera_03.png 230w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/08a84/ProFTP_Server_for_your_WQHD_Camera_03.png 460w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/c0255/ProFTP_Server_for_your_WQHD_Camera_03.png 920w", "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/1790f/ProFTP_Server_for_your_WQHD_Camera_03.png 1189w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/57ff88e63d6cb8c5386dbd3b86d7e0ea/c0255/ProFTP_Server_for_your_WQHD_Camera_03.png",
              "alt": "Set ProFTP Server for your WQHD Camera",
              "title": "Set ProFTP Server for your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And the log will tell you that the connection is now secure!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Status:	Connecting to `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111:21`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Connection established, waiting `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` welcome message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Initializing TLS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	TLS connection established.
Status:	Logged `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{`
Status:	Retrieving directory listing of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Status:	Directory listing of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span>{` successful`}</code></pre></div>
    <h2 {...{
      "id": "connecting-an-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-an-instar-ip-camera",
        "aria-label": "connecting an instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting an INSTAR IP Camera`}</h2>
    <p>{`We can now connect to our server using TLS encryption:`}</p>
    <h3 {...{
      "id": "wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wqhd-cameras",
        "aria-label": "wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WQHD Cameras`}</h3>
    <p>{`For your WQHD camera make sure to select the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`insecure`}</code>{` option to skip the certificate verification step when connecting:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/55fd9a5c45250b1a97536882cd5794e9/2e367/ProFTP_Server_for_your_WQHD_Camera_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDElEQVQoz22Rz0/TYByHdzCaGJCDMQqbrCttx9oyZLIyur1r+/bHurc/trc/03VDREbRxIsJicCI8eLRA0ePxr9gcvfgP2Zg0+zgk+f65JPv++aq1SrP8/xWtUwR2uEn/8sv92qKJj/Ny+lMNLnpTm7sq+nB199SegGl5maFXVtbKxaLOZZluTtoitTcQfL+c3hyHizYPzrrH525rz6E2aRtBjs723pH15DZxTg3K3meoxkGSmAQYex0sdP13JlIaYut/V1RqClgX6jVlh48EgRhcDg6ePd2HrMsS9M0VNVBOgzCyPP9f6qa3moBUWxKkoxsK79aWHm4glx7MH6Tq1QqPM9zHMcwDIQwSRL/Du8vuq5LkgQAaAOQDBPNNJbuLQmNxjAb38az8VmcpmkQBN4CqqoCABqNhiiKYRziyH+88mT7RS0dH/8njqJoMXYcx7Zty7IshOIk7gV49Wlh52U9PVlYvr0ZwtEwjcLA8/A89X3LckzT7BiGjZDUljZIOv+soOn66DSbfxXLsgxNywp0g9RwfLeHPXxHzzbkpiwDQVFEXavX95bvL1PUJrI6OI7nr81x7AZFWwb8ftH/ce5kIx8HURR4UXYZf/x2PD6/ttxrPzYkOV94XiqRBEUx5fLCMlMGzf3TUMk8gHRFVlSoyBD50HttmnjUAgcy3NvdXS+ukyRZIgiCKP4Bxqmw7pyYdr0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55fd9a5c45250b1a97536882cd5794e9/e4706/ProFTP_Server_for_your_WQHD_Camera_04.avif 230w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/d1af7/ProFTP_Server_for_your_WQHD_Camera_04.avif 460w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/7f308/ProFTP_Server_for_your_WQHD_Camera_04.avif 920w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/730d0/ProFTP_Server_for_your_WQHD_Camera_04.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/55fd9a5c45250b1a97536882cd5794e9/a0b58/ProFTP_Server_for_your_WQHD_Camera_04.webp 230w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/bc10c/ProFTP_Server_for_your_WQHD_Camera_04.webp 460w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/966d8/ProFTP_Server_for_your_WQHD_Camera_04.webp 920w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/fbb05/ProFTP_Server_for_your_WQHD_Camera_04.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55fd9a5c45250b1a97536882cd5794e9/81c8e/ProFTP_Server_for_your_WQHD_Camera_04.png 230w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/08a84/ProFTP_Server_for_your_WQHD_Camera_04.png 460w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/c0255/ProFTP_Server_for_your_WQHD_Camera_04.png 920w", "/en/static/55fd9a5c45250b1a97536882cd5794e9/2e367/ProFTP_Server_for_your_WQHD_Camera_04.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/55fd9a5c45250b1a97536882cd5794e9/c0255/ProFTP_Server_for_your_WQHD_Camera_04.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Checking the TLS log `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/var/log/proftpd/tls.log`}</code>{` you will see that your camera connects using `}<strong parentName="p">{`TLSv1.3`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:34:05,088 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13571`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: SSL/TLS-P requested, starting TLS handshake
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:34:05,100 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13571`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: client supports secure renegotiations
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:34:05,100 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13571`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: TLSv1.3 connection accepted, using cipher TLS_AES_256_GCM_SHA384 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span>{` bits`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:34:05,149 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13571`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: Protection `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` to Private
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:34:05,160 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13571`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: TLSv1.3 data connection accepted, using cipher TLS_AES_256_GCM_SHA384 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span>{` bits, resumed session`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3 {...{
      "id": "full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#full-hd-cameras",
        "aria-label": "full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full HD Cameras`}</h3>
    <p>{`For your Full HD camera make sure to select the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SSL`}</code>{` option:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e74239707c3db84a5521efcf31307f2c/e2e58/ProFTP_Server_for_your_WQHD_Camera_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB50lEQVQoz12QXW/SUACGSeYFXwOaQsNHoFg+OlvIJLjCOaeFDtcy1lboOaes3UZmXPQPmOxuuC1GvfBavfAnbDPxwt9nKIqJT97bJ0/yRmRZllutJ2KjO8Kj15/VxS1c3IKz/zc4fz969aHd0yeHZq1WLxaLgiBE1rLYEAazi/NPv4Kbh+PrB+/d/WZ0eU+u7oKbu5cff+5C8+luu1gqcRxXqVQikiTJsiyKIujtzbGDncOZPXbDYefQMvd1pOhIgUrnQEf12uNkMskwTCqVymazkTDcEkURIhScnFLqYULXI9SzHQciFSIVAGiOx82myDAMy7LpdDqXy0VaISsZQt/3CSH4L4QQ27Y1TVNVFUJomqaiKIVCIR3CcdyqLElSs9mEEAZB4Ps+pXQjW5alaRpCqN/vG4bR6XQymUw8Ho9Go6uyHLIunwS+f+xt2oRQy3aG+v5QGyAATMNotdvZbLZarfI8Xy6X/8jr8pT4k6lnTzF2V2B35hyZ4wP9+WSsGoZhWYIgJLe314fl8/l/b6sIfn374sfSunozm2FvTjE9vfAvv80vvyzp2Xd7eu3SaqMei0YfbW2xLMvz/OqwUN551u0Gjr6wwWQEAEQA9KE2RCaGhmv2oLunHAFUKJXisVgikRBCfgPwa5GdPcVd1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e74239707c3db84a5521efcf31307f2c/e4706/ProFTP_Server_for_your_WQHD_Camera_05.avif 230w", "/en/static/e74239707c3db84a5521efcf31307f2c/d1af7/ProFTP_Server_for_your_WQHD_Camera_05.avif 460w", "/en/static/e74239707c3db84a5521efcf31307f2c/7f308/ProFTP_Server_for_your_WQHD_Camera_05.avif 920w", "/en/static/e74239707c3db84a5521efcf31307f2c/c986f/ProFTP_Server_for_your_WQHD_Camera_05.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e74239707c3db84a5521efcf31307f2c/a0b58/ProFTP_Server_for_your_WQHD_Camera_05.webp 230w", "/en/static/e74239707c3db84a5521efcf31307f2c/bc10c/ProFTP_Server_for_your_WQHD_Camera_05.webp 460w", "/en/static/e74239707c3db84a5521efcf31307f2c/966d8/ProFTP_Server_for_your_WQHD_Camera_05.webp 920w", "/en/static/e74239707c3db84a5521efcf31307f2c/b8544/ProFTP_Server_for_your_WQHD_Camera_05.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e74239707c3db84a5521efcf31307f2c/81c8e/ProFTP_Server_for_your_WQHD_Camera_05.png 230w", "/en/static/e74239707c3db84a5521efcf31307f2c/08a84/ProFTP_Server_for_your_WQHD_Camera_05.png 460w", "/en/static/e74239707c3db84a5521efcf31307f2c/c0255/ProFTP_Server_for_your_WQHD_Camera_05.png 920w", "/en/static/e74239707c3db84a5521efcf31307f2c/e2e58/ProFTP_Server_for_your_WQHD_Camera_05.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e74239707c3db84a5521efcf31307f2c/c0255/ProFTP_Server_for_your_WQHD_Camera_05.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Checking the TLS log `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/var/log/proftpd/tls.log`}</code>{` you will see that your camera connects using `}<strong parentName="p">{`TLSv1.2`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:36:31,743 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13644`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: TLS/TLS-C requested, starting TLS handshake
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:36:31,789 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13644`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: client supports secure renegotiations
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:36:31,789 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13644`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: TLSv1.2 connection accepted, using cipher ECDHE-RSA-AES256-GCM-SHA384 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span>{` bits`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:36:31,887 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13644`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: Protection `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` to Private
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-05-26 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:36:31,943 mod_tls/2.9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13644`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`: TLSv1.2 data connection accepted, using cipher ECDHE-RSA-AES256-GCM-SHA384 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span>{` bits`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      